import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import OwlCarousel from "react-owl-carousel";
import { Helmet } from "react-helmet";
import Newsletter from "../components/Newsletter";
import brandsData from "../data/brands.json";
import parse from "html-react-parser";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../components/Breadcrumbs";
import ProductCard from "../components/ProductCard";
import { useQuery } from "@tanstack/react-query";
import { fetchProducts } from "../lib/medusa";

export default function Brands({setHeader}){
  const { slug } = useParams();
  const { t, i18n } = useTranslation();
  const [products,setProducts] = useState();
  const [count,setCount] = useState();
  useEffect(() => {
    const handleScroll = () => {
      const bottomOffset = 400;
      const reachedNearBottom =
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - bottomOffset;

      setIsOverlayVisible(!reachedNearBottom);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const { data ,refetch } = useQuery({
    queryKey: ["products"],
    queryFn: () => fetchProducts(slug,"created_at",9999,0),
  });

  useEffect(() => {
    setCurrentBrand(brandsData[slug]);
    refetch()
  }, [slug]);

  useEffect(() => {
    if(data)
    {
      setProducts(data[0])
      setCount(data[1])
    }
  },[data])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        setHeader(true);
      } else {
        setHeader(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [setHeader]);
  const [currentBrand, setCurrentBrand] = useState(brandsData[slug]);
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);



  return (
    <>
      <Helmet>
        {/* <!-- meta tag --> */}
        <meta charset="utf-8" />
        <title>
          {t(currentBrand.title)} - DURŌ Niche perfumery - Niche parfimerija
        </title>

        {/* <!-- Meta Tags--> */}
        <meta name="description" content={t("header.brands.metaDescription")} />
        <meta
          name="keywords"
          content="durō, duro, niche perfumery, niche parfimerija, beograd, parfimerija, galerija"
        />
        <meta name="author" content="intellect.se" />

        {/* <!-- responsive tag --> */}
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* <!-- Facebook Metadata --> */}
        <meta property="og:image:height" content="1200" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:title" content="DURŌ | Niche perfumery" />
        <meta
          property="og:description"
          content="DURŌ, where the art of niche perfumery meets ultimate luxury. We represent 25 of the most sought-after artisan perfume houses and offer a one-of-a-kind immersive experience in our boutique."
        />
        <meta property="og:url" content="https://www.parfimerija-duro.rs" />
        <meta
          property="og:image"
          content="https://www.parfimerija-duro.rs/assets/images/favicon/og-image.jpg"
        />
      </Helmet>
      <Loader />
      {/* // <!-- Main content Start --> */}
      <div className="main-content">
        {/* <!-- Breadcrumbs Start --> */}
        <div
          className="rs-breadcrumbs electimuss-3"
          style={{
            backgroundImage: `url(${
              currentBrand.heroImg === ""
                ? "/assets/images/breadcrumbs/inr-2.jpg"
                : currentBrand.heroImg
            })`,
          }}
        >
          <div className="container">
            <div className="breadcrumbs-inner">
              <h1 className="page-title">
                {currentBrand.title}
                {/*<span className="watermark">{currentBrand.title}</span>*/}
              </h1>
            </div>
          </div>
        </div>
        <Breadcrumbs bg={undefined} />
        {/* <!-- Breadcrumbs End --> */}

        {/* <!-- Intro Section Start --> */}
        <div className="rs-team style7 blue-bg height-ctrl md-pb-30 md-pt-30">
          {/* <!-- Overlay Shop Start --> */}
          <div
            className="container container-fixwidth"
            style={{ display: isOverlayVisible ? "block" : "none" }}
          >
            <div className="collection-overlay gray-bg4">
              <p className="collection-overlay-text">
                {products?.length} {t("brands.collectionProducts")}
              </p>
              {/* <!-- Shop online --> */}
              <div class="btn-online">
                <Link 
                    className="buy-online-button"
                      to={`/${i18n.language}/${
                        i18n.language === "en" ? "brands" : "brendovi"
                      }/${slug}/${
                        i18n.language === "en" ? "products" : "proizvodi"
                      }`}
                    >
                  <span className="btn-txt">{t("brands.allProducts")}</span>
                  <i className="fa fa-cart-plus"></i>
                </Link>
              </div>
            </div>
          </div>
          {/* <!-- Overlay Shop End --> */}
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-4 pr-15 md-pr-15 md-mb-50">
                <div className="brand-logo">
                  <img src={currentBrand.logo} alt={currentBrand.title} />
                </div>
              </div>
              <div className="col-lg-8 pl-45 md-pl-15">
                {currentBrand.lead === "" ? (
                  <p className="desc margin-0 pb-15">Coming soon...</p>
                ) : (
                  parse(t(currentBrand.lead))
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Intro Section End --> */}

        {/* <!-- Products Section Start --> */}
        {!products?.length && (
          <div className="loading-text blue-bg">Loading...</div>
        )}
        {products?.length > 0 ? (
          <div className="rs-team style7 blue-bg height-ctrl md-pb-20 md-pt-20">
            <div className="container">
              <div className="sec-title spotlight text-center mb-30">
                <span className="sub-text">
                  {t("brands.pageHeadings.spotlight")}
                </span>
                <h2 className="title pb-22">
                  {t("brands.pageHeadings.featured")}
                </h2>
                <div className="heading-border-line center-style"></div>
              </div>
              <OwlCarousel
                className="rs-carousel owl-carousel"
                loop={true}
                items={4}
                margin={30}
                autoplay={true}
                autoplayHoverPause={true}
                autoplayTimeout={5000}
                smartSpeed={800}
                dots={true}
                nav={true}
                navSpeed={false}
                center={false}
                navText={[
                  "<i className='flaticon-left-arrow'></i>",
                  "<i className='flaticon-right-arrow'></i>",
                ]}
                responsive={{
                  0: {
                    items: 1,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                  768: {
                    items: 2,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                  992: {
                    items: 3,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                  1200: {
                    items: 4,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                }}
              >
                {products.map((product, i) => (
                  <ProductCard product={product} key={i} />
                  // <div className="team-item" key={i}>
                  //   <div className="team-inner-wrap">
                  //     <div className="images-wrap">
                  //       <img src={product.img} alt={product.title} />
                  //     </div>
                  //     <div className="team-content">
                  //       <h3 className="title-name">
                  //         <a href="#!">{product.title} </a>
                  //       </h3>
                  //       <div className="team-title">{product.brand}</div>
                  //       <div className="team-price">
                  //         {product.price === "" ? "00.000,00" : product.price}{" "}
                  //         RSD | {product.volume !== "" && `${product.volume}`}
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                ))}
              </OwlCarousel>

              {/* <!-- All Products Button --> */}
              <div className="btn-all-prods">
                <Link
                  className="all-products-button"
                  to={`/${i18n.language}/${
                    i18n.language === "en" ? "brands" : "brendovi"
                  }/${slug}/${
                    i18n.language === "en" ? "products" : "proizvodi"
                  }`}
                >
                  {t("brands.allProducts")}
                  <i className="fa fa-cart-plus"></i>
                </Link>
              </div>
            </div>
          </div>
        ) : null}
        {/* <!-- Products Section End --> */}

        {/* <!-- About Section Start --> */}
        <div className="rs-about style1 blue-bg pt-20 pb-40 md-pt-20 md-pb-20">
          <div className="container">
            {currentBrand.story === "" ? null : (
              <div className="row y-middle pt-60 pb-40 md-pt-60 md-pb-20">
                <div className="col-lg-6 md-mb-50">
                  <div className="sec-title mb-60">
                    <span className="sub-text">
                      {t("brands.pageHeadings.about")}
                    </span>
                    <h2 className="title pb-22">
                      {currentBrand.name} <br />
                      {t(currentBrand.tagline)}
                    </h2>
                    <div className="heading-border-line left-style"></div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      {parse(t(currentBrand.story))}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 pl-50 md-pl-15">
                  <div className="images-part">
                    <img src={currentBrand.storyImg} alt={currentBrand.name} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <!-- About Section End --> */}

        {/* <!-- Quote Section Start --> */}
        {currentBrand.quote === "" ? null : (
          <div className="rs-team style2 blue-bg pt-50 pb-50 md-pt-50">
            <div className="container">
              <div className="sec-title text-center mb-60">
                <span className="sub-text">
                  {t("brands.pageHeadings.quote")}
                </span>
                <h2 className="title white-color pb-35">
                  "{t(currentBrand.quote)}"
                </h2>
                <div className="heading-border-line"></div>
              </div>
            </div>
          </div>
        )}
        {/* <!-- Quote Section End --> */}

        {/* <!-- Newsletter section start --> */}
        <div className="rs-newsletter blue-bg style1 pt-90 md-pt-60 mb--65">
          <Newsletter />
        </div>

        {/* <!-- Newsletter section end --> */}
      </div>
      {/* // <!-- Main content End --> */}
    </>
  );
}