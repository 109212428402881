import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { Helmet } from "react-helmet";
import Newsletter from "../components/Newsletter";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../components/Breadcrumbs";
import ProductCard from "../components/ProductCard";
import { useQuery } from "@tanstack/react-query";
import { fetchAllProducts } from "../lib/medusa";

export default function AllProducts({ setHeader }) {

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [limit, setLimit] = useState(12);
  const [offset, setOffset] = useState(0);
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState(0);

  let sort = searchParams.get('sort');

  const { isLoading, refetch } = useQuery({
    queryKey: ["all-products", sort, limit, offset],
    queryFn: () => fetchAllProducts(sort, limit, offset),
    onSuccess: (data) => {
      if (data?.length) {
        setProducts(data[0]);
        setCount(data[1]);
      }
    }
  });

  useEffect(() => {
    refetch();
  }, [offset, sort, limit, refetch]);

  const totalPages = Math.ceil(count / limit);

  const handleNextPage = () => {
    if (offset + limit < count) {
      setOffset(prevOffset => prevOffset + limit);
    }
  };

  const handlePreviousPage = () => {
    if (offset > 0) {
      setOffset(prevOffset => prevOffset - limit);
    }
  };

  const handleFirstPage = () => {
      setOffset(0);
  };
  
  const handleLastPage = () => {
    setOffset(limit*(totalPages-1));
  };


  useEffect(() => {
    sort = searchParams.get("sort");
    refetch();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        setHeader(true);
      } else {
        setHeader(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [setHeader]);

  const sortBy = (input) => {
    // Set the sort value in your state or variable (replace with your implementation)
    sort = input;
 

    // Create a URLSearchParams object
    const params = new URLSearchParams(window.location.search);

    // Check if the 'sort' parameter already exists
    if (params.has("sort")) {
      // If clicked input matches existing sort, remove the parameter
      if (params.get("sort") === input) {
        params.delete("sort");
      } else {
        // If clicked input is different, replace with new 'sort' value
        setSearchParams(`sort=${input}`)
        params.set('sort', input)
      }
    } else {
      // If no 'sort' parameter exists, append the new input
         setSearchParams(`sort=${input}`)
         params.append('sort', input)
    }

    // Create the new URL with the updated parameters (if necessary)
    const newUrl = params.toString()
      ? `${window.location.pathname}?${params.toString()}`
      : window.location.href;

    // Update the history state only if the URL changed
    if (newUrl !== window.location.href) {
      window.history.pushState({}, "", newUrl);
    }

    // Refetch data (assuming you have a refetch function)
    refetch();
  };
  return (
    <>
      <Helmet>
        {/* <!-- meta tag --> */}
        <meta charset="utf-8" />
        <title>
          All Products - DURŌ Niche perfumery - Niche parfimerija
        </title>

        {/* <!-- Meta Tags--> */}
        <meta name="description" content={t("header.brands.metaDescription")} />
        <meta
          name="keywords"
          content="durō, duro, niche perfumery, niche parfimerija, beograd, parfimerija, galerija"
        />
        <meta name="author" content="intellect.se" />

        {/* <!-- responsive tag --> */}
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* <!-- Facebook Metadata --> */}
        <meta property="og:image:height" content="1200" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:title" content="DURŌ | Niche perfumery" />
        <meta
          property="og:description"
          content="DURŌ, where the art of niche perfumery meets ultimate luxury. We represent 25 of the most sought-after artisan perfume houses and offer a one-of-a-kind immersive experience in our boutique."
        />
        <meta property="og:url" content="https://www.parfimerija-duro.rs" />
        <meta
          property="og:image"
          content="https://www.parfimerija-duro.rs/assets/images/favicon/og-image.jpg"
        />
      </Helmet>
      <Loader />
      {/* // <!-- Main content Start --> */}
      <div className="main-content">
         <div className="rs-breadcrumbs img8">
          <div className="container">
            <div className="breadcrumbs-inner">
              <h1 className="page-title">
                {t("header.products.pageTitle")}
              </h1>
            </div>
          </div>
        </div> 
        <Breadcrumbs />

        {/* <div className="rs-team style7 blue-bg height-ctrl md-pb-30 md-pt-30">
          <div
            className="container container-fixwidth"
            style={{ display: isOverlayVisible ? "block" : "none" }}
          >
            <div className="collection-overlay gray-bg4">
              <p className="collection-overlay-text">
                {products?.length} {t("brands.collectionProducts")}
              </p>
              <div class="btn-online">
                <Link to="#" className="buy-online-button">
                  <span className="btn-txt">{t("brands.allProducts")}</span>
                  <i className="fa fa-cart-plus"></i>
                </Link>
              </div>
            </div>
          </div>
        </div> */}

        {/* <!-- Products Section Start --> */}
        {isLoading && <div className="loading-text blue-bg">Loading...</div>} 
        {!isLoading && products?.length > 0 ? (
          <div className="rs-team style7 blue-bg height-ctrl md-pb-20 md-pt-20 product-load">
            <div className="container">
              <div className="row">
                {/* <!-- Filter Section --> */}
                <div className="col-md-2 col-12 filter-section">
                  {/* <!-- Add filter here --> */}
                  <div class="filter-section">
                    <span class="sort-label">{t("products.sortBy")}</span>
                    <ul class="sort-options">
                      <li>
                        <button
                          class={`filter-btn ${searchParams.get('sort') === "popular" && "active"}`}
                          onClick={() => sortBy("popular")}
                        >
                          {t("products.options.popular")}
                        </button>
                      </li>
                      <li>
                        <button
                          class={`filter-btn ${searchParams.get('sort') === "latest" && "active"}`}
                          onClick={() => sortBy("latest")}
                        >
                          {t("products.options.latest_arrivals")}
                        </button>
                      </li>
                      <li>
                        <button
                          class={`filter-btn ${
                            searchParams.get('sort') === "price_low" && "active"
                          }`}
                          onClick={() => sortBy("price_low")}
                        >
                          {t("products.options.price_low_high")}
                        </button>
                      </li>
                      <li>
                        <button
                          class={`filter-btn ${
                            searchParams.get('sort') === "price_high" && "active"
                          }`}
                          onClick={() => sortBy("price_high")}
                        >
                          {t("products.options.price_high_low")}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-10 col-12 products-section">
                  <div className="sec-title mb-40 mt-20 md-pb-20 md-pt-20">
                    <h2 className="title pb-22">{t("products.sub-title")}</h2>
                    <div className="heading-border-line left-style"></div>
                  </div>
                  <ul className="product-wrapper">
                    {products.map((product, i) => (
                      <li className="col-md-4 col-6 product-item" key={i}>
                        <ProductCard product={product} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        

        <div className="d-flex flex-row justify-content-center blue-bg gap-4">
        <button onClick={handleFirstPage}>
              First
            </button>
            <button onClick={handlePreviousPage} disabled={offset === 0}>
              Previous
            </button>
            <span>
              Page {Math.floor(offset / limit) + 1} of {totalPages}
            </span>
            <button onClick={handleNextPage} disabled={offset + limit >= count}>
              Next
            </button>
            <button onClick={handleLastPage}>
              Last
            </button>
          </div>
        {/* <!-- Products Section End --> */}

        {/* <!-- Newsletter section start --> */}
        <div className="rs-newsletter blue-bg style1 pt-90 md-pt-60 mb--65">
          <Newsletter />
        </div>

        {/* <!-- Newsletter section end --> */}
      </div>
      {/* // <!-- Main content End --> */}
    </>
  );
}
